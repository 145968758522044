import { useTranslation } from 'react-i18next';
import { TabPanel, Tabs } from '@/components';
import {
  HomeAttachments,
  HomesOfferingsReportPanel,
  PersonalizationSummaryTabContent,
  PersonalizationTabContent,
  ResidentsListPanel,
  useCommunityContext,
} from '@/views';
import { enumObject } from '@/utils';
import { useGetHomeDetailsQuery } from '@/redux';
import { CommunityPermissions } from '@/core';

const TAB_KEYS = [
  'residents',
  'personalization',
  'personalizationSummary',
  'documents',
  'offeringsReport',
] as const;
export const HomeDetailsTabKeys = enumObject('HomeDetailsTabKeys', TAB_KEYS);

const { TabPane } = Tabs;

interface Props {
  id: string;
  className?: string;
}

export const HomeDetailsTabsPanel = (props: Props) => {
  const { className, id } = props;
  const { data, isFetching } = useGetHomeDetailsQuery({ id });
  const { t } = useTranslation();

  const { hasPermission } = useCommunityContext();

  if (!data || isFetching) {
    return null;
  }
  const { communityId, projectId } = data;
  return (
    <Tabs
      className={className}
      tabKeys={HomeDetailsTabKeys}
      destroyInactiveTabPane
    >
      {hasPermission(CommunityPermissions.Residents.View) && (
        <TabPane
          tab={t('homes.details.tabs.residents')}
          key={HomeDetailsTabKeys.residents}
        >
          <ResidentsListPanel
            homeId={id}
            projectId={projectId}
            communityId={communityId!}
          />
        </TabPane>
      )}

      {data.personalizationHistory.length > 0 &&
        hasPermission(CommunityPermissions.Personalization.View) && (
          <TabPane
            tab={t('homes.details.tabs.personalization')}
            key={HomeDetailsTabKeys.personalization}
          >
            <PersonalizationTabContent
              personalizationHistory={data.personalizationHistory}
              homeId={id}
              communityId={data.communityId}
            />
          </TabPane>
        )}

      {data.personalizationHistory.length > 0 && (
        <TabPane
          tab={t('homes.details.tabs.personalizationSummary')}
          key={HomeDetailsTabKeys.personalizationSummary}
        >
          <PersonalizationSummaryTabContent
            personalizationHistory={data.personalizationHistory}
            homeId={id}
            communityId={data.communityId}
          />
        </TabPane>
      )}

      {hasPermission(CommunityPermissions.HomeDocuments.View) && (
        <TabPane
          tab={t('homes.details.tabs.documents')}
          key={HomeDetailsTabKeys.documents}
        >
          <TabPanel>
            <TabPanel.Title>{t('homes.details.attachments')}</TabPanel.Title>
            <TabPanel.Body>
              <HomeAttachments key={id} id={id} />
            </TabPanel.Body>
          </TabPanel>
        </TabPane>
      )}

      {hasPermission(CommunityPermissions.Reports.View) && projectId && (
        <TabPane
          tab={t('homes.details.tabs.offeringsReport')}
          key={HomeDetailsTabKeys.offeringsReport}
        >
          <HomesOfferingsReportPanel projectId={projectId} homeId={id} />
        </TabPane>
      )}
    </Tabs>
  );
};
